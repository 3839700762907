/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Progress,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { PiCaretDownLight } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import caution from "../../../assets/img/caution.png";
import Hazardus from "../../../assets/img/HazardousFlag.svg";
import Temperature from "../../../assets/img/TemperatureControlledFlag.svg";
import {
  fetchSKUCompareDetails,
  resetShowCollapsed,
  updateCollapsed,
} from "../../../redux/reducer/compareTableSlice";
import { convertToCurrencyFormat, formatDays } from "../../../services/utilService";

const { Text } = Typography;

function SUDetailCardPilot({ data, target, comparisonCostData, FinishedGoodKGWeight, transportationFlag, transporation }) {
  const {
    token: { colorPrimary, colorUnilever },
  } = theme.useToken();
  const dispatch = useDispatch();

  const { showCollpased } = useSelector((state) => state.compareTable);
  const [isHub, setIsHub] = useState(false)

  const [selectedSku, setSelectedSku] = useState();
  const [searchParams] = useSearchParams();
  const isRegionalView =
    searchParams.get("distributionCenter") === "null" ? false : true;
  const items = data.sku?.map((item) => {
    let key = Object.keys(item)[0];
    let value = Object.values(item)[0];
    return { key: key, label: value };
  });

  useEffect(() => {
    setSelectedSku(items[0]);

    return () => {
      dispatch(resetShowCollapsed());
    };
  }, []);

  const categoryUtilizationColumns = [
    {
      title: "Sub Family",
      dataIndex: "subfamily",
      key: "subfamily",
    },
    {
      title: "Line",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "Utilization %",
      dataIndex: "utilization",
      key: "utilization",
    },
  ];
  const handleSkuSelect = (e) => {
        const payload = {
      tarketSKU: searchParams.get("tarketSKUCode"),
      targetPlant: searchParams.get("tarketPlantCode"),
      brandCode: searchParams.get("brandCode"),
      alternativeSKU:
        items[0]["key"] === e.key
          ? data.sku?.map((item) => Object.keys(item)[0])
          : [e.key],
      alternativePlantCode: data?.plantCode,
      type: searchParams.get("type"),
    };
    dispatch(fetchSKUCompareDetails(payload));

    setSelectedSku(items.filter((item) => item.key === e.key)[0]);
  };
  const transporationCost = (byTon = false) => {
    const sum = transporation && transporation.transportationCost.reduce((accumulator, currentValue) => {
      return accumulator + (byTon ? Number(currentValue.Mean_Cost_per_Tonne) : Number(currentValue.Derived_Cost_per_1K_Units));
    }, 0);
    return sum
  };
  const transporationHubCost = (byTon = false) => {
    const sum = transporation && transporation.transportationCost.reduce((accumulator, currentValue) => {
      return accumulator + (byTon ? Number(currentValue.Mean_Cost_per_Tonne_hub) : Number(currentValue.Derived_Cost_per_1K_Units_hub));
    }, 0);
    return sum
  };
  const Price = (byTon = false) => {
    return (Number(data?.rmCost) || 0) + (Number(data.pmCost) || 0) + (Number(data.conversionCost) || 0) + (byTon ? 0 : Number(transporationCost(byTon) || 0));
  }
  const PriceHub = (byTon = false) => {
    return (Number(data?.rmCost) || 0) + (Number(data.pmCost) || 0) + (Number(data.conversionCost) || 0) + (byTon ? 0 : Number(transporationHubCost(byTon) || 0));
  }
  const noOfUnitsPerTon = 1000 / FinishedGoodKGWeight.FinishedGoodKGWeight;
  const transporationCostPerTon = Number(transporationCost(true));
  const transporationHubCostPerTon = Number(transporationHubCost(true));
  const PricePerTon = ((Price(true) / 1000) * (noOfUnitsPerTon)) + Number(transporationCost(true) || 0)
  const PriceHubPerTon = ((PriceHub(true) / 1000) * (noOfUnitsPerTon)) + Number(transporationHubCost(true) || 0)
  const getDutiesAndTaxes = (byTon = false) => {
    var total = 0;
    for (const x of comparisonCostData) {
      // eslint-disable-next-line no-unused-vars
      const { RateA, RateB, RateC, Average } = x;
      if (x.Formula.includes("Max")) {
        total += eval(x.Formula.replace("Max", "Math.max")) * (byTon ? PricePerTon : Price());
      } else if (x.Formula.includes("number of units of measure per shipment")) {
        total += eval(x.Formula.replace("number of units of measure per shipment", byTon ? `${noOfUnitsPerTon}` : "1000"));
      } else {
        total += eval(x.Formula.replace("Price", byTon ? "PricePerTon" : "Price()"));
      }
    }

    return total;
  }
  const getDutiesAndTaxesHub = (byTon = false) => {
    var total = 0;
    for (const x of comparisonCostData) {
      // eslint-disable-next-line no-unused-vars
      const { RateA, RateB, RateC, Average } = x;
      if (x.Formula.includes("Max")) {
        total += eval(x.Formula.replace("Max", "Math.max")) * (byTon ? PriceHubPerTon : Price());
      } else if (x.Formula.includes("number of units of measure per shipment")) {
        total += eval(x.Formula.replace("number of units of measure per shipment", byTon ? `${noOfUnitsPerTon}` : "1000"));
      } else {
        total += eval(x.Formula.replace(/Price/g, byTon ? "PriceHubPerTon" : "PriceHub()"));
      }
    }

    return total;
  }
  useEffect(() => {
    setIsHub(transporation && !(transporation.transportationCost.every(obj => obj["Mean_Cost_per_Tonne_hub"] === null && obj["Derived_Cost_per_1K_Units_hub"] === null)))
  }, [transporation])
  return (
    <Card bordered className="su-details-card">
      <div
        style={{
          padding: 8,
          textAlign: "center",
          backgroundColor: "#EBF3FF",
          borderBottom: "1px solid #f0f0f0",
        }}>
        <Text strong style={{ fontSize: 14 }}>
          Plant: {data?.plantName}
        </Text>
        <br />
        <Text style={{ fontSize: 11 }}>
          {data?.country} | {data?.city}
        </Text>
        <br />
        <Text>{data?.Dimension}</Text>

        <Row align="middle" style={{ padding: "8px 0" }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Space>
              <Text>Match Avg</Text>
              <div style={{ marginTop: 4 }}>
                <Progress
                  type="circle"
                  strokeColor={colorPrimary}
                  // percent={data.PLMMatchPercentage}
                  percent={
                    searchParams.get("type") === "commodity"
                      ? Math.round(((data.commPercentage.rawMaterialMatchPercentage + data.commPercentage.packingMaterialMatchPercentage) / 2) * 100) / 100
                      : Math.round(((data.plmMatchPercentage.rawMaterialMatchPercentage + data.plmMatchPercentage.packingMaterialMatchPercentage) / 2) * 100) / 100
                  }

                  size={16}
                  strokeWidth={24}
                />
              </div>

              {/* <Text strong>{data.PLMMatchPercentage}%</Text> */}
             <Text strong>{searchParams.get("type")==="commodity"? Math.round(((data.commPercentage.rawMaterialMatchPercentage + data.commPercentage.packingMaterialMatchPercentage) / 2) * 100) / 100
                      : Math.round(((data.plmMatchPercentage.rawMaterialMatchPercentage + data.plmMatchPercentage.packingMaterialMatchPercentage) / 2) * 100) / 100}%</Text>
           
            </Space>
          </Col>
          {searchParams.get("tarketPlantCode") === data?.plantCode ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Space align="center" style={{ gap: 4 }}>
                <MdInfoOutline
                  size={16}
                  color={colorUnilever}
                  style={{ marginBottom: -4 }}
                />
                <Text>Current Sourcing Unit</Text>
              </Space>
            </Col>
          ) : null}
        </Row>
      </div>

      <Dropdown
        menu={{
          items,
          onClick: handleSkuSelect,
        }}
        trigger={["click"]}
        placement="bottom"
        dropdownRender={(menu) => (
          <div style={{ height: 200, overflow: "auto" }}>
            {React.cloneElement(menu)}
          </div>
        )}>
        <Space
          align="center"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 16px 0 16px ",
          }}>
          <Text strong style={{ color: colorPrimary }}>
            {selectedSku?.label}
          </Text>
          <PiCaretDownLight size={12} color={colorPrimary} />
        </Space>
      </Dropdown>
      <Divider style={{ margin: "8px 0" }} />

      <div style={{ padding: "0 16px 16px 16px" }}>
        <Row align="middle">
          <Col span={12}>
            <Text strong>Pack Size</Text>
          </Col>
          <Col span={12}>
            <Text>{data?.Dimension}</Text>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />

        <Row>
          <Col span={12}>
            <Text strong>Raw Materials</Text>
          </Col>
          <Col span={12}>
            <Text title="what percentage of the raw materials in the BOM of the target SKU are present at this factory/sku.">
              {/* {Math.round(
                (data.rawMaterial.totalMatch / target.targetRMCount) * 100
              )} */}
              {searchParams.get("type")==="commodity"?data.commPercentage.rawMaterialMatchPercentage:data.plmMatchPercentage.rawMaterialMatchPercentage}%
              
            </Text>
          </Col>
          <Col span={24}>
            <Collapse
              size="small"
              bordered={false}
              ghost
              activeKey={showCollpased.rm}
              onChange={(key) => {
                dispatch(updateCollapsed({ rm: key }));
              }}
              items={[
                {
                  key: "1",
                  label: `Match (${data.rawMaterial.totalMatch})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.rawMaterial.match?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
                {
                  key: "2",

                  label: `Missing (${data.rawMaterial.totalMissing})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.rawMaterial.missing?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
                {
                  key: "3",
                  label: `Extra (${data.rawMaterial.totalExtra})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.rawMaterial.extra?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />

        <Row>
          <Col span={12}>
            <Text strong>Pack. Materials</Text>
          </Col>
          <Col span={12}>
            <Text title="what percentage of the pack. materials in the BOM of the target SKU are present at this factory/sku.">
              {/* {Math.round(
                (data.packagingMaterial.totalMatch / target.targetPMCount) * 100
              )} */}
               {searchParams.get("type")==="commodity"?data.commPercentage.packingMaterialMatchPercentage:data.plmMatchPercentage.packingMaterialMatchPercentage}%
              
            </Text>
          </Col>
          <Col span={24}>
            <Collapse
              size="small"
              bordered={false}
              ghost
              activeKey={showCollpased.pm}
              onChange={(key) => {
                dispatch(updateCollapsed({ pm: key }));
              }}
              items={[
                {
                  key: "1",
                  label: `Match (${data.packagingMaterial.totalMatch})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.packagingMaterial.match?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
                {
                  key: "2",

                  label: `Missing (${data.packagingMaterial.totalMissing})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.packagingMaterial.missing?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
                {
                  key: "3",
                  label: `Extra (${data.packagingMaterial.totalExtra})`,
                  children: (
                    <ul
                      style={{
                        maxHeight: 120,
                        overflow: "auto",
                        listStyleType: "disc",
                      }}>
                      {data.packagingMaterial.extra?.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      )) || "-"}
                    </ul>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />

        <Row>
          <Col span={12} style={{ paddingLeft: 16 }}>
            <Text style={{ color: "#7D7D7D" }}>Cost Per</Text>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <Text style={{ color: "#7D7D7D" }}>1000 pc</Text>
              </Col>
              <Col span={12}>
                <Text style={{ color: "#7D7D7D" }}>Per Tonne</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Text strong>Estimated Ex-Works</Text>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <Text title="Per 1000 pieces">
                  {convertToCurrencyFormat(data?.cost)}
                </Text>
              </Col>
              <Col span={12}>
                <Text>{convertToCurrencyFormat(data?.costPerTonne)}</Text>
              </Col>

            </Row>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row>
                <Col span={12} style={{ paddingLeft: 16 }}>
                  <Text>RM Cost</Text>
                  {data?.rmFlag === 1 ? (
                    <img
                      src={caution}
                      alt="caution"
                      style={{ marginBottom: 2, marginLeft: 4, height: 16 }}
                      title={
                        "Caution: Some materials and/or Conversion costs are Unspecified, Zero(0) or Extremely Low"
                      }
                    />
                  ) : null}
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Text title="Per 1000 pieces">
                        {convertToCurrencyFormat(data?.rmCost)}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text>{convertToCurrencyFormat(data?.rmCostPerTonne)}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ paddingLeft: 16 }}>
                  <Text>PM Cost</Text>
                  {data?.pmFlag === 1 ? (
                    <img
                      src={caution}
                      alt="caution"
                      style={{ marginBottom: 2, marginLeft: 4, height: 16 }}
                      title={
                        "Caution: Some materials and/or Conversion costs are Unspecified, Zero(0) or Extremely low"
                      }
                    />
                  ) : null}
                </Col>
                <Col span={12}>
                  <Row>
                  <Col span={12}>
                  <Text title="Per 1000 pieces">
                    {convertToCurrencyFormat(data?.pmCost)}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{convertToCurrencyFormat(data?.pmCostPerTonne)}</Text>
                </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ paddingLeft: 16 }}>
                  <Text>Conversion Cost</Text>
                  {data?.ccFlag === 1 ? (
                    <img
                      src={caution}
                      alt="caution"
                      style={{ marginBottom: 2, marginLeft: 4, height: 16 }}
                      title={
                        "Caution: Some materials and/or Conversion costs are Unspecified, Zero(0) or Extremely low"
                      }
                    />
                  ) : null}
                </Col>
                <Col span={12}>
                  <Row>
                  <Col span={12}>
                  <Text title="Per 1000 pieces">
                    {convertToCurrencyFormat(data?.conversionCost)}
                  </Text>
                </Col>
                <Col span={12}>
                <Text>{convertToCurrencyFormat(data?.conversionCostPerTonne)}</Text>
                </Col>
                  </Row>
                </Col>

              </Row>
            </Space>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />

        {isRegionalView ? (
          <>
            <Row>
              <Col span={24}>
                <Text strong>Transportation</Text>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {transportationFlag && transportationFlag.HazardousFlag && <div style={{ margin: "1px", padding: '2px 6px', backgroundColor: 'rgba(255,0,0,0.1)', borderRadius: '4px' }}><span style={{ padding: "1px" }}><img src={Hazardus} alt="Hazordus" /></span><Text strong >Dangerous Goods</Text></div>}
                  {transportationFlag && transportationFlag.TemperatureControlledFlag && <div style={{ margin: "1px", padding: '2px 6px', backgroundColor: 'rgba(253,170,20,0.3)', borderRadius: '4px' }}><span style={{ padding: "1px" }}><img src={Temperature} alt="Temperature Controlled" /></span><Text strong >Temperature Controlled</Text></div>}
                </div>
              </Col>

              <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row>
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text>Route Existence</Text>
                    </Col>
                    <Col span={12}>
                      <Text>{transporation && transporation.rootExistence}</Text>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text style={{ visibility: transporation && transporation.leadTime ? "visible" : "hidden" }}>Lead Time</Text>
                    </Col>
                    <Col span={12}>
                      <Text>{transporation && transporation.leadTime && formatDays(Math.ceil(transporation.leadTime))}</Text>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Divider style={{ margin: "8px" }} />

                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text style={{ color: "#7D7D7D" }}>Cost Per</Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text style={{ color: "#7D7D7D" }}>1000 pc</Text>
                        </Col>
                        <Col span={12}>
                          <Text style={{ color: "#7D7D7D" }}>Per Tonne</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text>Transportation Cost - Direct </Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(transporationCost())}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(transporationCostPerTon)}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {isHub && <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text>Transportation Cost - Hub</Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(transporationHubCost())}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(transporationHubCostPerTon)}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}
                  <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text>Duties and Taxes</Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(getDutiesAndTaxes())}</Text>
                        </Col>
                        <Col span={12}>
                          <Text>{convertToCurrencyFormat(getDutiesAndTaxes(true))}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text strong>Total - Direct</Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text strong>{convertToCurrencyFormat(getDutiesAndTaxes() + Price())}</Text>
                        </Col>
                        <Col span={12}>
                          <Text strong>{convertToCurrencyFormat(getDutiesAndTaxes(true) + PricePerTon)}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {isHub && <Row align="middle">
                    <Col span={12} style={{ paddingLeft: 16 }}>
                      <Text strong>Total - Hub</Text>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <Text strong>{convertToCurrencyFormat(getDutiesAndTaxesHub() + PriceHub())}</Text>
                        </Col>
                        <Col span={12}>
                          <Text strong>{convertToCurrencyFormat(getDutiesAndTaxesHub(true) + PriceHubPerTon)}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>}
                </Space>
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0" }} />
          </>
        ) : null}

        <Row>
          <Col span={24}>
            <Collapse
              size="small"
              id="cu"
              bordered={false}
              ghost
              activeKey={showCollpased.capacityUtilization}
              onChange={(key) => {
                dispatch(updateCollapsed({ capacityUtilization: key }));
              }}
              items={[
                {
                  key: "1",
                  label: (
                    <Row>
                      <Col span={12}>
                        <Text strong>Capacity Utilization</Text>
                      </Col>
                      <Col span={12}>
                        <Text>{data.capacity}%</Text>
                      </Col>
                    </Row>
                  ),
                  children: data?.capacityCategory?.map((obj, index) => (
                    <Collapse
                      key={index}
                      size="small"
                      bordered={false}
                      ghost
                      items={[
                        {
                          key: "1",
                          label: (
                            <Row>
                              <Col span={12}>
                                <Text strong>{obj.category}</Text>
                              </Col>
                              <Col span={12}>
                                <Text>{obj.percetage}%</Text>
                              </Col>
                            </Row>
                          ),
                          children: (
                            <Table
                              size="small"
                              bordered={false}
                              pagination={false}
                              scroll={{ y: 120 }}
                              dataSource={obj.categoryTable}
                              columns={categoryUtilizationColumns}
                              showHeader={false}
                            />
                          ),
                        },
                      ]}
                    />
                  )),
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default SUDetailCardPilot;
