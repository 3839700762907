import { Button, theme } from "antd";
import React from "react";

function Feedback() {
  const {
    token: { colorUnilever },
  } = theme.useToken();

  return (
    <div style={{ position: "fixed", right: -84, top: "60vh", zIndex: 1 }}>
      <Button
        type="primary"
        style={{
          transform: "rotate(270deg)",
          transformOrigin: "left",
          backgroundColor: colorUnilever,
          borderRadius: "4px 4px 0 0",
        }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://unilever.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=ead322721b138550abe6db98bd4bcb24"
          style={{ textDecoration: "underline" }}>
          Feedback
        </a>
      </Button>
    </div>
  );
}

export default Feedback;
