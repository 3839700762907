import { useMsal } from "@azure/msal-react";
import { Dropdown, Radio, Space, Typography, theme } from "antd";
import React, { useEffect, useState } from "react";
import {
  MdHelpOutline,
  MdHome,
  MdLogout,
  MdNotificationsNone,
  MdOutlineAccountCircle,
} from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import caution from '../../assets/img/caution.png';

import RTVA from "../../assets/img/logo_RTVA.svg";
import UnileverLogo from "../../assets/img/logo_unilever.svg";
import { checkUserInfo } from "../../services/homeService";
import { msalConfig } from "../../sso/authConfig";
import DownloadCSV from "../Compare/SUDetails/DownloadCSV";

const { Title, Text } = Typography;

function AppHeader() {
  const {
    token: { colorPrimary, colorUnilever },
  } = theme.useToken();
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === "/simulate" ? "Simulate" : "Compare"
  );
  const [isNutriUser, setIsNutriUser] = useState(0);

  useEffect(() => {
    if (location.pathname === "/simulate") {
      setCurrentPath("Simulate");
    } else {
      setCurrentPath("Compare");
    }

    checkIfNutriUser();
  }, [location]);

  const checkIfNutriUser = async () => {
    const response = await checkUserInfo();

    if (response.success) {
      setIsNutriUser(response.data || 0);
    }
  };

  const signOutClickHandler = (instance) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      mainWindowRedirectUri: msalConfig.auth.redirectUri,
      postLogoutRedirectUri: msalConfig.auth.redirectUri,
    };
    instance.logoutPopup(logoutRequest);
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          Welcome <Title level={5}>{accounts?.[0]?.name}</Title>
        </>
      ),
    },
    {
      key: "2",
      label: <div onClick={() => signOutClickHandler(instance)}>Sign Out</div>,
      icon: <MdLogout size={14} />,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 40,
          backgroundColor: colorPrimary,
          padding: "0 24px",
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <img src={RTVA} alt="RTVA" />
          <Title
            level={5}
            style={{
              margin: 0,
              color: "#fff",
              marginLeft: 8,
              textTransform: "uppercase",
            }}>
            Sourcing Identification
          </Title>
        </div>

        <div
          style={{
            padding: 4,
            height: "100%",
          }}>
          <img src={UnileverLogo} alt="Unilever" style={{ height: "100%" }} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 56,
          backgroundColor: colorUnilever,
          padding: "0 24px",
        }}>
        <Space size="middle">
          <Link to="">
            <MdHome size={32} color="#fff" />
          </Link>
          <Radio.Group
            defaultValue="Compare"
            className="nav-radio-group"
            style={{ width: 172 }}>
            <Radio.Button
              value={currentPath}
              onClick={() => {
                setCurrentPath("Compare");
                navigate({
                  pathname: "",
                });
              }}
              style={{
                backgroundColor:
                  currentPath === "Compare" ? "#0044AC" : colorUnilever,
              }}>
              <Text
                style={{ color: "#FFFFFF" }}
                strong={currentPath === "Compare"}>
                Compare
              </Text>
            </Radio.Button>
            <Radio.Button
              value={currentPath}
              onClick={() => {
                setCurrentPath("Simulate");

                navigate({
                  pathname: "simulate",
                });
              }}
              style={{
                backgroundColor:
                  currentPath === "Simulate" ? "#0044AC" : colorUnilever,
              }}>
              <Text
                style={{ color: "#FFFFFF" }}
                strong={currentPath === "Simulate"}>
                Simulate
              </Text>
            </Radio.Button>
          </Radio.Group>
          <div style={{ marginLeft: 5 }}>
              {location.pathname === "/home" ? (
            <>
            <img
              src={caution}
              alt="caution"
              style={{ marginBottom: 2, marginLeft: 4, height: 16 }}
              title="Caution: Some materials and/or Conversion costs are Unspecified, Zero(0) or Extremely Low"
            />
            <Text style={{ color: "#FFFFFF", fontSize: 9 ,marginLeft: 5}}>
              Fusion region data is currently unavailable in the tool but will be added soon
            </Text>
      
            </>
            ) : (
            <Text style={{ color: "#FFFFFF", fontSize: 9 }}>
             Logistics costs, based on Cost to Serve data, offer directional and indicative insights to guide in-depth analysis and sourcing decisions. Hub network lane costs in SI are determined by DC shipment volumes in North America while market hub strategies consider dynamic factors like plant and customer proximity, due to which average variations can be around 10% for some SKUs when compared for actual costs in SAP.
            </Text>
            )}
          </div>

        </Space>

        <Space size="middle">
          

          <DownloadCSV />

          <Link to="glossary">
            <MdNotificationsNone size={24} color="#fff" />
          </Link>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div style={{ cursor: "pointer" }}>
              <MdOutlineAccountCircle size={24} color="#fff" />
            </div>
          </Dropdown>
          <Link to="support">
            <MdHelpOutline size={24} color="#fff" />
          </Link>
        </Space>
      </div>
    </div>
  );
}

export default AppHeader;
